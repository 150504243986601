module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Crowdlinker","short_name":"Crowdlinker","start_url":"/","background_color":"#fc544c","theme_color":"#fc544c","display":"minimal-ui","icon":"src/images/favicon@2x.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5PHQ83","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://0856bb81169d43bfb54f62089ac1232e@sentry.io/1872104","environment":"production","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
